import React from "react";
import "./Cover.css";
import DW from "../../media/DW.mp4"

const Cover = () => {
  return (
    <div className="cover-container">
      <video className="video" src={DW} autoPlay loop muted />
      <title>PRECIOS desde $110.000 PAGO UNICO</title>
      <title>DISEÑO Y DESARROLLO DE PAGINAS WEB</title> 
      <h3>Web Simple | Landing Page | Profesional | E-commerce | Presencia en Internet a Empresa o Negocio </h3>
    </div>
  );
};

export default Cover;